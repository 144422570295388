<template>
  <div>
    <el-container>
      <tree-layout
        treeTitle="组织架构"
        :tree-options="treeOptions"
        :tree-loading="treeLoading"
        @tree-node-click="treeNodeClick"
        @include-down="includeDown"
        style="margin-right: 5px"
      ></tree-layout>

      <el-container>
        <el-main>
          <head-layout head-title="未选人员列表"> </head-layout>
          <grid-head-layout
            ref="gridHeadLayout"
            :grid-head-btn="gridHeadBtn"
            :search-columns="leftSearchColumns"
            :deviceProtocol="deviceProtocol"
            @grid-head-search="leftGridHeadSearch"
            @grid-head-empty="leftGridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            class="deparmentLayout"
            ref="leftGridLayOut"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="leftTableData"
            :table-loading="leftTableLoading"
            :data-total="leftPage.total"
            :page="leftPage"
            @page-current-change="leftOnLoad"
            @page-size-change="leftOnLoad"
            @page-refresh-change="leftOnLoad"
          >
          </grid-layout>
        </el-main>
        <div class="btn">
          <el-button
            style="width: 40px; height: 40px; align-items: center"
            type="primary"
            icon="el-icon-arrow-left"
            :disabled="false"
            :loading="false"
            circle
            @click="leftRemove"
          ></el-button>
          <el-button
            style="width: 40px; height: 40px; align-items: center"
            type="primary"
            icon="el-icon-arrow-right"
            :disabled="false"
            :loading="false"
            circle
            @click="rightAdd"
          ></el-button>
        </div>
        <el-main>
          <head-layout head-title="已选人员列表"> </head-layout>
          <grid-head-layout
            ref="gridHeadLayout"
            :grid-head-btn="gridHeadBtn"
            :search-columns="reghtSearchColumns"
            :deviceProtocol="deviceProtocol"
            @grid-head-search="rightGridHeadSearch"
            @grid-head-empty="rightGridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            ref="rightGridLayOut"
            class="deparmentLayout"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="rightTableData"
            :table-loading="rightTableLoading"
            :data-total="rightPage.total"
            :page="rightPage"
            @page-current-change="rightOnLoad"
            @page-size-change="rightOnLoad"
            @page-refresh-change="rightOnLoad"
          >
          </grid-layout>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { getDeptLazyTree } from "@/api/system/dept";
import {
  pageDeptByRole,
  roleAllocationByList as roleAllocation,
  addDeptByRole,
  removeDeptByRole,
} from "@/api/system/user";
import { getDeptTree } from "@/api/system/dept";
let baseUrl = "/api/sinoma-system/dict/dictionary?code=";
import { mapGetters } from "vuex";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import TreeLayout from "@/views/components/layout/tree-layout";

export default {
  name: "protocol",
  components: {
    GridLayout,
    HeadLayout,
    TreeLayout,
  },
  props: {
    currentRole: {},
  },
  data() {
    return {
      treeLoading: true,
      leftTableLoading: true,
      rightTableLoading: true,
      leftTableData: [],
      rightTableData: [],
      deviceProtocol: this.verdict,
      leftQuery: {},
      rightQuery: {},
      query: {},
      getDeptTreeDicData: [],
      leftPage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      rightPage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      treeOptions: {
        defaultExpandAll: false,
        nodeKey: "id",
        lazy: true,
        treeLoad: (node, resolve) => {
          let _this = this;
          _this.treeLoading = true;
          const parentId = node.level === 0 ? 0 : node.data.id;
          getDeptLazyTree(parentId).then((res) => {
            _this.treeLoading = false;
            resolve(
              res.data.data.map((item) => {
                return {
                  ...item,
                  leaf: !item.hasChildren,
                };
              })
            );
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: "title",
          value: "value",
          children: "children",
        },
      },
    };
  },
  computed: {
    treeLoading: true,
    ...mapGetters(["permission"]),
    leftSearchColumns() {
      return [
        {
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.account"),
          prop: "account",
          cell: true,
          align: "left",
          width: 150,
          overHidden: true,
        },
        {
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.realName"),
          prop: "realName",
          cell: true,
          align: "left",
          width: 150,
          overHidden: true,
        },
      ];
    },
    reghtSearchColumns() {
      return [
        {
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.account"),
          prop: "account",
          cell: true,
          align: "left",
          width: 150,
          overHidden: true,
        },
        {
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.realName"),
          prop: "realName",
          cell: true,
          align: "left",
          width: 150,
          overHidden: true,
        },
      ];
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        menu: false,
        selection: true,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
            align: "left",
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            cell: true,
            width: 150,
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            cell: true,
            align: "left",
            overHidden: true,
          },
        ],
      };
    },
    gridRowBtn() {
      return [];
    },
  },
  gridHeadBtn() {
    return [];
  },
  headBtnOptions() {},
  mounted() {
    console.log("进行输出----------", this.currentRole);
    getDeptTree("000000").then((res) => {
      this.getDeptTreeDicData = res.data.data;
      this.leftOnLoad(this.leftPage);
      this.rightOnLoad(this.rightPage, {});
    });
  },
  methods: {
    /**
     * 向右边增加
     */
    rightAdd() {
      let selectionList = this.$refs.leftGridLayOut.selectionList;
      if (selectionList.length == 0) {
        this.$message({
          message: "请选择左侧列表人员",
          type: "warning",
        });
        return;
      }
      let ids = selectionList.filter((e) => e.id).map((e) => e.id);
      let cc = [];
      selectionList.forEach((e) => {
        let sub = {
          userId: e.id,
          deptId: e.deptId,
        };
        cc.push(sub);
      });
      let addUser = {
        roleId: this.currentRole.id,
        userDeptList: cc,
      };
      addDeptByRole(addUser).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: this.$t("omit.successmsg"),
            type: "success",
          });
        }
        this.rightOnLoad(this.rightPage, {});
      });
    },
    /**
     * 向左边删除
     */
    leftRemove() {
      let selectionList = this.$refs.rightGridLayOut.selectionList;
      if (selectionList.length == 0) {
        this.$message({
          message: "请选择右侧列表人员",
          type: "warning",
        });
        return;
      }
      let ids = selectionList.filter((e) => e.id).map((e) => e.id);
      let cc = [];
      selectionList.forEach((e) => {
        let sub = {
          userId: e.id,
          deptId: e.deptId,
        };
        cc.push(sub);
      });
      let addUser = {
        roleId: this.currentRole.id,
        userDeptList: cc,
      };
      removeDeptByRole(addUser).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: this.$t("omit.successmsg"),
            type: "success",
          });
        }
        this.rightOnLoad(this.rightPage, {});
      });
    },
    includeDown(e) {
      this.leftPage.currentPage = 1;
      this.query.isInclude = e;
      this.leftOnLoad(this.leftPage);
    },
    /**
     * 部门树结构点击事件
     * @param node
     */
    treeNodeClick(node) {
      this.treeDeptId = node.id;
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.leftPage);
    },
    leftGridHeadSearch(query) {
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.leftPage, query);
    },
    leftGridHeadEmpty(query) {
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.leftPage, query);
    },
    leftOnLoad(page, params = {}) {
      this.param = params;
      this.leftQuery = params;
      this.leftPage = page;
      this.leftTableLoading = true;
      roleAllocation(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.leftQuery),
        this.treeDeptId
      ).then((res) => {
        const data = res.data.data;
        // this.$refs.leftGridLayOut.leftPage.total = data.total;
        this.leftPage.total = data.total;
        this.leftTableData = data.records;
        this.leftTableLoading = false;
      });
    },
    rightGridHeadSearch(query) {
      this.rightPage.currentPage = 1;
      this.rightOnLoad(this.rightPage, query);
    },
    rightGridHeadEmpty(query) {
      this.rightPage.currentPage = 1;
      this.rightOnLoad(this.rightPage, query);
    },
    findChildData(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id === childId) {
          // 如果当前节点就是目标子节点，直接返回其数据
          return node;
        } else if (node.children) {
          // 否则递归查找当前节点的子节点
          const childData = this.findChildData(node.children, childId);
          if (childData) {
            // 如果找到了目标子节点的数据，返回该数据
            return childData;
          }
        }
      }
      // 没有找到目标子节点的数据，返回 null
      return null;
    },
    rightOnLoad(page, params = {}) {
      this.param = params;
      console.log("进行输出-----------", params);
      this.rightQuery.roleId = this.currentRole.id;
      this.rightPage = page;
      this.rightTableLoading = true;
      pageDeptByRole(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.rightQuery)
      ).then((res) => {
        const data = res.data.data;
        // this.$refs.rightGridLayOut.rightPage.total = data.total;
        this.rightPage.total = data.total;
        data.records.forEach((e) => {
          e.deptName = this.findChildData(
            this.getDeptTreeDicData,
            e.deptId
          ).title;
        });
        this.rightTableData = data.records;
        this.rightTableLoading = false;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.btn {
  display: flex;
  align-items: center;
}
::v-deep .deparmentLayout .avue-crud .el-table {
  height: calc(100vh - 348px) !important;
  max-height: calc(100vh - 348px) !important;
}

::v-deep .el-tree {
  height: calc(100vh - 311px) !important;
}
</style>
